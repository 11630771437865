import React, { useState } from "react"
import PortableText from "@sanity/block-content-to-react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import { P, H1, H2, H3 } from "../components/Typography"
import {GatsbyImage} from 'gatsby-plugin-image'
import * as Sections from "../components/SectionElements"
import ContentCard from "../components/ContentCard"
import serializer from "../components/NonArticleSerializer"
import SEO from "../components/seo"

const HomeContainer = styled.div`
  grid-column: 1 / span 6;
  grid-row: 3 / span 1;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: min-content;
  column-gap: calc(12 / 1680 * 100vw);
  margin-top: ${props => props.theme.spacings.large};
  margin-bottom: ${props => props.theme.spacings.large};
  border-top: 0;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 1 / span 12;
    grid-row: 2 / span 1;
    grid-template-columns: repeat(12,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    margin-top: 70px;
    margin-bottom: ${props => props.theme.spacings.largest};
`

const HomeTitle = styled(H1)`
  grid-column: span 6;
  grid-row: 1 / span 1;
  padding-bottom: ${props => props.theme.spacings.xLarge};
  align-self: end;
  line-height: 1.2;
  font-weight: 350;
  font-size: 6vw;

  @media ${props => props.theme.breakpoints.desktop} {
      grid-column: 1 / span 6;
      grid-row: 1 / span 1;
      line-height: 1;
      font-size: 4vw;
      padding-bottom: 0;
  }
`
const ImageOptionsBox = styled.div`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  text-align: left;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  position: relative;
  align-self: start;
  padding-bottom: ${props => props.theme.spacings.large};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 8 / span 1;
    grid-row: 1 / span 4;
    flex-direction: column-reverse;
    padding-bottom: 0;
  }
`
const ImageContainer = styled.div`
  width: 10%;
  margin-left: ${props => props.theme.spacings.xxSmall};
  transition: transform 0.3s;

  > div {
    border-radius: 10%;
  }

  :active {
    transform: scale(0.9);
    transition: transform 0.3s;
  }

  @media ${props => props.theme.breakpoints.desktop} {
    width: 50%;
    margin-left: 0;
    margin-bottom: ${props => props.theme.spacings.xxSmall};
  
    :hover {
      cursor: pointer;
      transform: scale(0.95);
      transition: transform 0.3s;
    }
  }
`
const AnimContainer = styled.div`
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    padding-bottom: ${props => props.theme.spacings.small};
    padding-top: 0;
    position: relative;
    align-self: start;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 9 / span 3;
        grid-row: 1 / span 4;
        padding-bottom: 0;
        padding-top: 0;
    }
`
const AnimImage = styled(GatsbyImage)`
    width: 100%;
    height: auto;
    border-radius: 20px;
`
const AnimSvg = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
`

const RecentSection = styled.div`
  grid-column: span 6;
  grid-row: span 1;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: min-content;
  column-gap: calc(12 / 1680 * 100vw);
  padding-bottom: ${props => props.theme.spacings.xLarge};
  // padding-top: ${props => props.theme.spacings.xxLarge};
  border-top: 0;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: span 12;
    grid-template-columns: repeat(12,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    padding-bottom: 0;
    // padding-top: ${props => props.theme.spacings.largest};
  }
`

const RecentList = styled.ul`
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: auto;
  column-gap: calc(6 / 1680 * 100vw)};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(12,minmax(0,1fr));
    column-gap: calc(12 / 1680 * 100vw);
    grid-column: 1 / span 12;

    :hover > li {
      opacity: 0.5;
    }

    > li:hover {
      opacity: 1;
    }
  }
`

const SectionTitle = styled.div`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  padding-bottom: ${props => props.theme.spacings.large};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 1 / span 12;
`
const BackDark = styled.span`
  font-size: 12px;
  font-weight: 375;
  padding-top: ${props => props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.theme.spacings.xxSmall};
  padding-left: ${props => props.theme.spacings.xSmall};
  padding-right: ${props => props.theme.spacings.xSmall};
  border-radius: 5px;
  margin-right: 2px;
  margin-bottom: 2px;
  color: ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.foreground};
`
const ExtButton = styled(Link)`
    background-color: ${props => props.theme.colors.gray3};
    color: ${props => props.theme.colors.foreground};
    margin-right: 2px;
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;

    :hover {
        background-color: ${props => props.theme.colors.gray2};
        color: ${props => props.theme.colors.foreground};
    }

    @media ${props => props.theme.breakpoints.desktop} {
        box-shadow: none;
    }
`

const SmallText = styled.div`
  grid-column: 1 / span 6;
  grid-row: 4 / span 1;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  align-self: start;
  padding-bottom: ${props => props.theme.spacings.xxLarge};

  @media ${props => props.theme.breakpoints.desktop} {
      grid-column: 1 / span 6;
      grid-row: 1 / span 1;
      padding:0;
  }
`

const TaglineBox = styled.div`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 1;
  align-self: end;

  @media ${props => props.theme.breakpoints.desktop} {
      grid-column: 8 / span 5;
      grid-row: 1 / span 1;
  }
`

const TaglinePart = styled.h2`
  margin: ${props => props.margin ? props.margin : 0};
  font-size: 15px;
  line-height: 1.25;
  color: ${props => props.theme.colors.foreground};
  font-weight: 350;
  padding-top: ${props => props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.theme.spacings.xxSmall};
  padding-left: ${props => props.theme.spacings.xSmall};
  padding-right: ${props => props.theme.spacings.xSmall};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.gray2};
  margin-right: 2px;
  margin-bottom: 2px;

  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 15px;
  }
`

const PartnerLogo = styled.div`
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  margin-bottom: 50px;
  width: 50%;
  transform-origin: top left;
  transition: transform 0.3s;

  :hover {
    transform: scale(1.2,1.2);
    transform-origin: top left;
    transition: transform 0.3s;
  }

  @media ${props => props.theme.breakpoints.desktop} {
      margin-bottom: 100px;
  }
`

const IndexPage = ({data}) => {
  const HPnode = data.allSanityHomepage.edges[0].node
  const RecentNodes = data.allSanityPost.nodes
  const PartnersNodes = data.allSanityPartner.nodes

  function splitSentence(sentence) {
    const sentenceParts = sentence.split(" ")
    return sentenceParts
  }
  
  const [currentAnim, setCurrentAnim] = useState(0);

  return (
    <Layout>
      <SEO/>
      <HomeContainer>
        <AnimContainer>
          <AnimImage image={HPnode.variousImages[currentAnim].image.asset.gatsbyImageData} alt=""/>
          <AnimSvg dangerouslySetInnerHTML={{__html: HPnode.variousImages[currentAnim].svg}}/>
        </AnimContainer>
        <ImageOptionsBox>
          {HPnode.variousImages.map((hpimage, i) => {
            return (
              <ImageContainer key={i} onClick={() => setCurrentAnim(i)}>
                <GatsbyImage image={hpimage.image.asset.gatsbyImageData} alt=""/>
              </ImageContainer>
            )
          })}
        </ImageOptionsBox>
        <SmallText>
          <PortableText 
          blocks = {HPnode._rawIntrotext}
          serializers = {serializer}
          />
        </SmallText>
      </HomeContainer>
      <HomeTitle>Chaire<br/>Pluralisme culturel &<br/>&#201;thique du num&#233;rique</HomeTitle>
      <TaglineBox>
        {splitSentence(`Pôle de recherche et d’échanges hébergé par la Fondation Panthéon-Sorbonne`).map((part, j) => {
            return (
                <TaglinePart key={j}>{part}</TaglinePart>
            ) 
        })}
      </TaglineBox>
      <Sections.SixSection>
        <SectionTitle>
          <BackDark>
              Partenaires
          </BackDark>
        </SectionTitle>
        {PartnersNodes.map((partner, i) => {
            return (
                <Sections.SixSectionDiv key={i}>
                    <PartnerLogo>
                        <a href={partner.website} target='_blank' rel='noopener noreferrer' alt={partner.company}><GatsbyImage image={partner.logo.asset.gatsbyImageData} alt=""/></a>
                    </PartnerLogo>
                </Sections.SixSectionDiv>
            )
        })}
      </Sections.SixSection>
      <RecentSection>
        <SectionTitle>
          <BackDark>
              Articles r&eacute;cents
          </BackDark> 
          <ExtButton to="/activites">
              Voir plus
          </ExtButton> 
        </SectionTitle>
        <RecentList>
          {RecentNodes.map((recentArticle, i) => {
            return (
              <ContentCard key={recentArticle.slug.current}
                slug = {recentArticle.slug.current}
                title = {recentArticle.title}
                excerpt = {recentArticle.excerpt}
                image = {recentArticle.mainImage.asset.gatsbyImageData}
                date = {recentArticle.publishedAt}
                authors = {recentArticle.authors}
                type = {recentArticle.type}
                sector = {recentArticle.secteur}
              />
            )
          })}
        </RecentList>
      </RecentSection>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    allSanityHomepage {
      edges {
        node {
          _rawIntrotext
          variousImages {
            image {
              asset {
                gatsbyImageData(width: 800)
              }
            }
            svg
          }
        }
      }
    }
    allSanityPartner(sort: {fields: company, order: ASC}) {
      nodes {
        company
        website
        logo {
          asset {
            gatsbyImageData(width: 400)
          }
        }
      }
    }
    allSanityPost(sort: {fields: publishedAt, order: DESC}, skip: 0, limit: 4) {
      nodes {
        mainImage {
          asset {
            gatsbyImageData(width: 800)
          }
        }
        slug {
          current
        }
        title
        excerpt
        publishedAt(formatString:"DD-MM-YYYY")
        authors {
          firstname
          surname
        }
        type
        secteur
      }
    }
  }
`
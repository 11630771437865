import React from "react"
import {H1, H2, H3, H4, P, Li} from "../components/Typography"
import styled from "styled-components"

const BodyLink = styled.a`
  color: ${props => props.theme.colors.foreground};
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.colors.gray1};

  :hover {
    color: ${props => props.theme.colors.foreground};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.colors.foreground};
  }
`

const BlockUl = styled.ul`
  margin-left: ${props => props.theme.spacings.xLarge};
`
const BackDark = styled.span`
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;
    // margin-right: 2px;
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.foreground};
`
const BackGray = styled.span`
    font-size: 12px;
    font-weight: 375;
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    border-radius: 5px;
    margin-right: 2px;
    color: ${props => props.theme.colors.foreground};
    background-color: ${props => props.theme.colors.gray2};
`

const ListWrapper = styled.ol`
    list-style: none;
    counter-reset: custom-counter;

    grid-column: 1 / span 6;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 2 / span 6;
    }
`

const ListItem = styled.li`
    font-size: 15px;
    font-weight: 350;
    line-height: 1.5;
    position: relative;
    counter-increment: custom-counter;
    padding-bottom: ${props => props.theme.spacings.small};
    text-indent: 50px;

    :before {
        content: counter(custom-counter);
        color: ${props => props.theme.colors.foreground};
        font-size: 12px;
        position: absolute;
        top: 0;
        left: 0px;
        line-height: 1;
        text-indent: 0;
        padding-top: ${props => props.theme.spacings.xxSmall};
        padding-bottom: ${props => props.theme.spacings.xxSmall};
        padding-left: ${props => props.theme.spacings.xSmall};
        padding-right: ${props => props.theme.spacings.xSmall};
        background-color: ${props => props.theme.colors.gray2};
        border-radius: 5px;
        text-align: center;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        text-indent: 50px;
        :before {
            left: -50px;
            text-indent: 50px;
        }
    }
`

const serializer = {
    types: {
        block: props => {
          const style = props.node.style || "normal";
  
          if (style === "h3") {
            return <H3>{props.children}</H3>;
          } else if (style === "h4") {
            return <H4>{props.children}</H4>;
          } else {
            return <P context="block">{props.children}</P>
          }
        }
    },
    list: (props) =>
        props.type === 'bullet' ? (
            <BlockUl>{props.children}</BlockUl>
        ) : (
            <ListWrapper>{props.children}</ListWrapper>
        ),
    listItem: (props) =>
        props.type === 'bullet' ? (
            <Li context="block">{props.children}</Li>
        ) : (
            <ListItem>{props.children}</ListItem>
        ),
    marks: {
        link: props =>
            props.mark.blank ? (
            <BodyLink href={props.mark.href} target='_blank' rel='noopener noreferrer'>{props.children}</BodyLink> 
            ) : (
            <BodyLink href={props.mark.href}>{props.children}</BodyLink>
            ),
        count: props => (
            <BackGray>{props.children}</BackGray>
            ),
        highlight: props => (
            <BackDark>{props.children}</BackDark>
        )
    }
}

export default serializer
import React from "react"
import {Link} from "gatsby"
import {H1, H2, H3, H4, P, Li} from "./Typography"
import {GatsbyImage} from 'gatsby-plugin-image'
import styled from "styled-components"

const ContentItem = styled.li`
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: auto;
    column-gap: calc(6 / 1680 * 100vw)};
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
    border-top: 1px solid ${props => props.theme.colors.gray2};
    border-bottom: 0;
    align-items: baseline;
    position: relative;
    
    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(12,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 1 / span 12;
        grid-row: span 1;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: 0;
        border-bottom: 0;
        border-top: 0;

        :hover > .infogrid {
            border-top: 1px solid ${props => props.theme.colors.secondary};
        }

        :hover > .content-img {
            z-index: 9;
            visibility: visible;
            transform: translateY(-20px);
            transition: transform 0.3s;
        }
    }
`
const InfoGrid = styled.div`
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: auto;
    column-gap: calc(6 / 1680 * 100vw)};
    padding-top: ${props => props.theme.spacings.large};
    padding-bottom: ${props => props.theme.spacings.large};
    border-bottom: 0;
    margin-bottom: 0;
    align-items: baseline;
    position: relative;
    
    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(10,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 3 / span 10;
        grid-row: span 1;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: ${props => props.theme.spacings.large};
        padding-bottom: ${props => props.theme.spacings.xLarge};
        border-bottom: 0;
        border-top: 1px solid ${props => props.theme.colors.gray2};
        :hover {
            border-top: 1px solid ${props => props.theme.colors.foreground};
        }
    }
`

const ContentHeader = styled.div`
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 5;
        grid-row: 1 / span 1;
    }
`

const ContentTitle = styled(H3) `
    padding-top: 0;
    padding-bottom: ${props => props.theme.spacings.small};
`

const ContentExcerpt = styled(P)`
    padding-bottom: ${props => props.theme.spacings.large};

    @media ${props => props.theme.breakpoints.desktop} {
        padding-top: 0;
        padding-bottom: 0;
    }
`
const ContentDate = styled(P)`
    grid-column: 4 / span 2;
    grid-row: 2 / span 1;
    text-align: left;
    text-transform: uppercase;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 10 / span 1;
        grid-row: 1 / span 1;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
    }
`
const ContentAuthors = styled.ul `
    grid-column: 1 / span 3;
    grid-row: 2 / span 1;
    list-style: none;
    padding-bottom: ${props => props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 8 / span 2;
        grid-row: 1 / span 1;
        padding-top: 0;
        padding-bottom: 0;
    }
`
const ContentType = styled.div `
    grid-column: 1 / span 5;
    grid-row: 3 / span 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: ${props => props.theme.spacings.small};


    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 6 / span 2;
        grid-row: 1 / span 1;
        padding-top: 0;
        padding-bottom: 0;
    }
`

const MetadataElement = styled.div`
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.xSmall};
    padding-right: ${props => props.theme.spacings.xSmall};
    display: block;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.gray3};
    margin-right:2px;
    margin-bottom: 2px;
    pointer-events: none;
`
const ContentImg = styled(Link)`
    display: none;

    @media ${props => props.theme.breakpoints.desktop} {
        display: block;
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
        align-self: start;
        width: 100%;
        position: absolute;
        margin-top: 20px;
        border-radius: 15px;
        top: 0;
        left: 0;
        z-index: 0;
        visibility: hidden;
        overflow: hidden;
        will-change: transform;
    }
`

const MobileImg = styled(Link)`
    display: block;
    position: relative;
    grid-column: 6 / span 1;
    grid-row: 2 / span 2;
    width: 65%;
    align-self: start;
    justify-self: right;

    > div {
        border-radius: 5px;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        display: none;
    }
`

const ContentCard = ({onMouseOver, slug, title, excerpt, date, image, authors, type, sector}) => {
    function pinned (postDate) {
        if (postDate === "31-12-3332") {
            const pinned = '◇ épinglé'
            return pinned
        } else {
            return postDate
        }
    }

    return (
        <ContentItem
        onMouseOver={onMouseOver}
        >
            <ContentImg className='content-img' to={`/activites/${slug}`}>
                <GatsbyImage image={image} alt=""/>
            </ContentImg>
            <InfoGrid className='infogrid'>
                <ContentHeader>
                    <ContentTitle>
                        <Link to={`/activites/${slug}`}>{title}</Link>
                    </ContentTitle>
                    <ContentExcerpt context="block">
                        {excerpt}
                    </ContentExcerpt>
                </ContentHeader>
                <MobileImg to={`/activites/${slug}`}>
                    <GatsbyImage image={image} alt=""/>
                </MobileImg>
                <ContentAuthors>
                        {authors.map((author, i) => (
                            <Li context="smalltext" key={i}>
                                {author.firstname} {author.surname}
                            </Li>
                        ))}
                </ContentAuthors>
                <ContentDate context="smalltext">
                        {pinned(date)}
                    </ContentDate>
                <ContentType>
                        {type.map((typ, i) => (
                            <MetadataElement key={i}>
                                <P context="smalltextbubble">{typ}</P>
                            </MetadataElement>
                        ))}
                        {sector.map((sect, i) => (
                            <MetadataElement key={i}>
                                <P context="smalltextbubble">{sect}</P>
                            </MetadataElement>
                        ))}
                </ContentType>
            </InfoGrid>
        </ContentItem>
    )
};

export default ContentCard
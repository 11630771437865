import React from 'react';
import styled from "styled-components"

const SectionWrapper = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;
    padding-top: ${props => props.theme.spacings.medium};
    // padding-bottom: ${props => props.theme.spacings.largest};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(7,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 1 / span 7;
    }
`

const TextWrapper = styled.div`
    padding-bottom: ${props => props.theme.spacings.large};
    grid-column: 1 / span 6;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 2 / span 6;
    }
`

const ListWrapper = styled.ol`
    list-style: none;
    counter-reset: custom-counter;

    grid-column: 1 / span 6;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 2 / span 6;
    }
`

const ListItem = styled.li`
    font-size: 15px;
    font-weight: 350;
    line-height: 1.5;
    position: relative;
    counter-increment: custom-counter;
    padding-bottom: ${props => props.theme.spacings.small};
    text-indent: 50px;

    :before {
        content: counter(custom-counter);
        color: ${props => props.theme.colors.foreground};
        font-size: 12px;
        position: absolute;
        top: 0;
        left: 0px;
        line-height: 1;
        text-indent: 0;
        padding-top: ${props => props.theme.spacings.xxSmall};
        padding-bottom: ${props => props.theme.spacings.xxSmall};
        padding-left: ${props => props.theme.spacings.xSmall};
        padding-right: ${props => props.theme.spacings.xSmall};
        background-color: ${props => props.theme.colors.gray2};
        border-radius: 5px;
        text-align: center;
    }

    @media ${props => props.theme.breakpoints.desktop} {
        text-indent: 50px;
        :before {
            left: -50px;
            text-indent: 50px;
        }
    }
`

const TwoSectionContainer = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(8,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 3 / span 8;
    }
`
const TwoSectionChild = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(4,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: span 4;
    }
`

const ThreeSectionContainer = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(12,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 1 / span 12;
    }
`
const ThreeSectionChild = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;
    padding-bottom: ${props => props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(4,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: span 4;
    }
`

const FourSectionContainer = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(12,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 1 / span 12;
    }
`
const FourSectionChild = styled.div`
    grid-column: span 3;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(3,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: span 3;
    }
`
const SixSectionContainer = styled.div`
    grid-column: 1 / span 6;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;
    padding-bottom: ${props => props.theme.spacings.xxLarge};

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(12,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 1 / span 12;
        padding-bottom: 0;
    }
`
const SixSectionChild = styled.div`
    grid-column: span 2;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    grid-auto-rows: min-content;
    column-gap: calc(12 / 1680 * 100vw);
    position: relative;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(2,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: span 2;
    }
`

export const Section = ({children}) => {
    return (
        <SectionWrapper>
            {children}
        </SectionWrapper>
    )
}

export const Text = ({children}) => {
    return (
        <TextWrapper>
            {children}
        </TextWrapper>
    )
}

export const List = ({children}) => {
    return (
        <ListWrapper>
            {children}
        </ListWrapper>
    )
}

export const ListChild = ({children}) => {
    return (
        <ListItem>
            {children}
        </ListItem>
    )
}

export const TwoSection = ({children}) => {
    return (
        <TwoSectionContainer>
            {children}
        </TwoSectionContainer>
    )
}

export const TwoSectionDiv = ({children}) => {
    return (
        <TwoSectionChild>
            {children}
        </TwoSectionChild>
    )
}

export const ThreeSection = ({children}) => {
    return (
        <ThreeSectionContainer>
            {children}
        </ThreeSectionContainer>
    )
}

export const ThreeSectionDiv = ({children}) => {
    return (
        <ThreeSectionChild>
            {children}
        </ThreeSectionChild>
    )
}

export const FourSection = ({children}) => {
    return (
        <FourSectionContainer>
            {children}
        </FourSectionContainer>
    )
}

export const FourSectionDiv = ({children}) => {
    return (
        <FourSectionChild>
            {children}
        </FourSectionChild>
    )
}

export const SixSection = ({children}) => {
    return (
        <SixSectionContainer>
            {children}
        </SixSectionContainer>
    )
}

export const SixSectionDiv = ({children}) => {
    return (
        <SixSectionChild>
            {children}
        </SixSectionChild>
    )
}